// Layout
const dashboardLayout = () => import('@/layouts/CompanyLayout/DashboardLayout/dashboardLayout.vue')

const processing = () => import('./views/index')

// start Routes
export default [
  {
    path: '/company/processing',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'companyProcessing',
      meta: {
        name: 'companyProcessing',
        parentName: 'processing',
        userType: '',
        permission: '',
        role: 'transportation.company_owner'
      },
      component: processing
    }
    ]
  }
]
