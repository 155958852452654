/* eslint-disable eqeqeq */
export default {
  methods: {
    hasPer (nameOfRole) {
      const roles = JSON.parse(localStorage.getItem('my-roles') || '[]')
      return roles.indexOf(nameOfRole) > -1
    },
    isType: () => true,
    isAdmin: () => true
  }
}
